<template>
    <div class="PurchaseRefundDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div>
                <el-form @submit.native.prevent :inline="true" size="small" label-width="100px">
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="采购订单号">
                                <el-input readonly v-model="form.bizCode" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="退货机构">
                                <el-input readonly v-model="form.deptName" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="配送中心">
                                <el-input readonly v-model="form.repoName" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="客户">
                                <el-input readonly v-model="form.customer" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="客户电话">
                                <el-input readonly v-model="form.customerMobile" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="客户地址">
                                <el-input readonly v-model="form.customerAddress" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="创建人">
                                <el-input readonly v-model="form.creator" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="创建日期">
                                <el-input v-model="form.createTime" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <el-button type="primary" @click="handleAudit" size="small">审核详情</el-button>
            </div>
        </el-card>
        <PickupReturnAudit ref="PickupReturnAudit" :review-privilege-flag="'menu.purchase.refund.review'" />
        <el-card shadow="never" style="margin-top: 8px" align="left">
            <el-table
                border
                stripe
                style="width: 100%"
                :data="form.detailParams"
                size="mini"
                :highlight-current-row="true"
                max-height="450"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column label="商品名称" width="180" fixed="left">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).name }}
                    </template>
                </el-table-column>
                <el-table-column label="条码" width="130">
                    <template slot-scope="scope">
                        <div v-for="b in skuByGoodsCode(scope.row.goodsCode).bars" :key="b">{{ b }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="规格" width="100">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                    </template>
                </el-table-column>
                <el-table-column label="类目" width="100">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).category }}
                    </template>
                </el-table-column>
                <el-table-column label="单位" width="80">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                    </template>
                </el-table-column>
                <el-table-column label="品牌" width="100">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                    </template>
                </el-table-column>
                <el-table-column label="退货价" width="80" prop="showPrices" v-if="showInPrice()" />
                <template v-if="enabledBigUnit">
                    <el-table-column label="包装因子" prop="packFactors" width="100" />
                    <el-table-column label="退货箱数" width="100" prop="bigUnitCounts" />
                    <el-table-column label="散装数量" width="100" prop="bulkCounts" />
                </template>
                <el-table-column label="退货数量" width="100" prop="counts" />
                <el-table-column label="退货金额" width="120" prop="purchaseRefundShowMoney" v-if="showInPrice()" />
                <el-table-column label="退货赠品数量" width="100" prop="giftCounts" />
                <el-table-column label="退货总数量" width="100" prop="totalCounts" />
            </el-table>
        </el-card>
    </div>
</template>
<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import PickupReturnAudit from 'components/PickupReturnAudit';
import MoneyUtils from 'js/MoneyUtils';
import StockBizCommon, { PropertyConverter } from 'js/StockBizCommon';
import GoodsCommon from 'js/GoodsCommon';

export default {
    name: 'PurchaseRefundDetail',
    components: { PickupReturnAudit },
    props: {
        code: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            baseUrl: '/purchase/refund/apply',
            form: {
                bizCode: '',
                createTime: '',
                creator: '',
                deptName: '',
                repoName: '',
                deptCode: '',
                repoCode: '',
                customer: '',
                customerMobile: '',
                customerAddress: '',
                detailParams: [],
            },
            //所选商品详情列表
            goodsDetailMap: new Map(),
            enabledBigUnit: false,

            codes: [],
            tableData: [],
        };
    },
    mounted() {
        //查询数据
        UrlUtils.QueryRemote(this, `${this.baseUrl}/info_c/${this.code}`, (data) => {
            Util.copyProperties(data, this.form);
            //是否启用大单位权限
            UrlUtils.HasBigUnitPrivilege(this, this.form.deptCode, async (rst) => {
                this.enabledBigUnit = rst;
                const arr = await StockBizCommon.makeStockBizDetailGoodsAndParams(
                    data,
                    this.detailParamPropertyArrForShow()
                );
                this.goodsDetailMap = arr.goodsDetails;
                this.form.detailParams = arr.detailParams;
            });
        });
    },
    methods: {
        detailParamPropertyArrForShow() {
            const rst = [
                'codes',
                'goodsCode',
                'counts',
                'giftCounts',
                new PropertyConverter('prices', 'showPrices', (prices) => {
                    return MoneyUtils.moneyToYuan(prices);
                }),
            ];
            if (this.enabledBigUnit) {
                rst.push(
                    new PropertyConverter('packFactors', 'packFactors', (packFactors, detailParam) => {
                        return Util.isEmpty(packFactors) ? 1 : packFactors;
                    }),
                    new PropertyConverter('bigUnitCounts', 'bigUnitCounts', (bigUnitCounts, detailParam) => {
                        return Util.isEmpty(bigUnitCounts)
                            ? Math.floor(detailParam.counts / detailParam.packFactors)
                            : bigUnitCounts;
                    }),
                    new PropertyConverter('bulkCounts', 'bulkCounts', (bulkCounts, detailParam) => {
                        return Util.isEmpty(bulkCounts) ? detailParam.counts % detailParam.packFactors : bulkCounts;
                    })
                );
            }
            rst.push(
                new PropertyConverter(null, 'purchaseRefundShowMoney', (saleShowMoney, detailParam) => {
                    return this.decimal(detailParam.counts)
                        .mul(detailParam.showPrices || 0)
                        .toNumber();
                }),
                new PropertyConverter(null, 'totalCounts', (totalCounts, detailParam) => {
                    return this.decimal(detailParam.counts).add(detailParam.giftCounts).toNumber();
                })
            );
            return rst;
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },

        handleAudit() {
            this.$refs.PickupReturnAudit.show(this.code, this.baseUrl);
        },
    },
    filters: {
        skuTypeDesc(type) {
            return GoodsCommon.skuTypeDesc(type);
        },
        m(m) {
            return MoneyUtils.formatMoney(m);
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
